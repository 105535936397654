<template>
  <div>
    <div class="ssWeb-container-baner">
      <Banner />
    </div>
    <div class="ProductCenter-container">
      <!-- 首页轮播图 -->
      <div class="ProductCenter-container-header">
        <SwiperCom
          :srcAry="imgAray"
          :imgWidth="1920"
          :imgheight="1080"
          :swiperType1="true"
          :swiperType2="false"
        />
      </div>
      <!-- 产品轮播图 -->
      <div class="ProductCenter-container-product">
        <h3 class="column-title">
          <span
            >产品中心<br />
            Product Center</span
          >
        </h3>
        <ProductSwiper
          :srcAry="imgAray1"
          :imgWidth="200"
          :imgheight="200"
          :swiperDelay="1500"
          :productNumber="3"
          :swiperType1="false"
          :swiperType2="true"
          :isButton="false"
        />
      </div>
    </div>
    <div class="ssWeb-container-footer">
      <Footer />
    </div>
  </div>
</template>

<script>
import SwiperCom from "../components/SwiperCom/index.vue";
import ProductSwiper from "../components/ProductSwiper/index.vue";
import Banner from "@/pages/Banner.vue";
import Footer from "@/pages/Footer.vue";
export default {
  components: {
    SwiperCom,
    ProductSwiper,
    Banner,
    Footer,
  },
  data() {
    return {
      imgAray: [{ imgUrl: require("../assets/banner/bn8.jpg") }],
      imgAray1: [
        {
          imgUrl: require("../assets/product/productPicture/1.png"),
          name: "1年老缸酱油495ML",
        },
        {
          imgUrl: require("../assets/product/productPicture/2.png"),
          name: "2年老缸酱油495ML",
        },
        {
          imgUrl: require("../assets/product/productPicture/3.png"),
          name: "3年老缸酱油495ML",
        },
        {
          imgUrl: require("../assets/product/productPicture/4.png"),
          name: "1年老缸酱油275ml",
        },
        {
          imgUrl: require("../assets/product/productPicture/5.png"),
          name: "2年老坛陈醋495ML",
        },
        {
          imgUrl: require("../assets/product/productPicture/6.png"),
          name: "3年老坛陈醋495ML",
        },
        {
          imgUrl: require("../assets/product/productPicture/7.png"),
          name: "5年老坛陈醋495ML",
        },
        {
          imgUrl: require("../assets/product/productPicture/8.png"),
          name: "2年老坛陈醋275ML",
        },
        {
          imgUrl: require("../assets/product/productPicture/9.png"),
          name: "川派料酒495ML",
        },
        {
          imgUrl: require("../assets/product/productPicture/10.png"),
          name: "浓香料酒495ML",
        },
        {
          imgUrl: require("../assets/product/productPicture/11.png"),
          name: "浓香料酒275ML",
        },
        {
          imgUrl: require("../assets/product/productPicture/12.jpg"),
          name: "中秋礼盒1.02L",
        },
        {
          imgUrl: require("../assets/product/productPicture/13.jpg"),
          name: "伴手礼盒510ML",
        },
        {
          imgUrl: require("../assets/product/productPicture/14.jpg"),
          name: "初味礼盒990ML",
        },
        {
          imgUrl: require("../assets/product/productPicture/15.jpg"),
          name: "初味礼盒1.98L",
        },
        {
          imgUrl: require("../assets/product/productPicture/16.jpg"),
          name: "沐光礼盒990ML",
        },
        {
          imgUrl: require("../assets/product/productPicture/17.jpg"),
          name: "沐光礼盒1.98L",
        },
        {
          imgUrl: require("../assets/product/productPicture/18.jpg"),
          name: "蕴藏礼盒990ML",
        },
      ],
    };
  },
};
</script>

<style scoped>
.ProductCenter-container {
  width: 80%;
  margin: 0 auto;
  text-align: center;
}
.column-title {
  font-size: 20px;
  color: #fff;
  margin-bottom: 25px;
  font-weight: 400;
  text-align: center;
}

.column-title span {
  background: #b90d0d;
  display: inline-block;
  padding: 5px 60px;
  border-radius: 40px;
  position: relative;
}
.column-title span:before {
  content: "";
  width: 8px;
  height: 8px;
  background: #fff;
  display: block;
  position: absolute;
  margin-left: -20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  box-sizing: border-box;
}
@media (max-width: 768px) {
  .column-title {
    font-size: 12px;
  }
  .ProductCenter-container {
    width: 100%;
  }
}
</style>
