import Vue from "vue";
import VueRouter from "vue-router";
import HomePage from '@/pages/HomePage';
import CompanyIntroduction from '@/pages/CompanyIntroduction';
import ProductCenter from '@/pages/ProductCenter';
import BrandCulture from '@/pages/BrandCulture';
Vue.use(VueRouter)

const router = new VueRouter({
    routes: [
        { path: '/', name: 'Home', redirect: '/home' },
        { path: '/home', name: 'Home', component: HomePage },
        { path: '/about', name: 'About', component: CompanyIntroduction },
        { path: '/product', name: 'Product', component: ProductCenter },
        { path: '/culture', name: 'Culture', component: BrandCulture },
    ]
})
export default router