<template>
  <div>
    <div class="ssWeb-container-baner">
      <Banner />
    </div>
    <div class="companyIntroduction-container">
      <div class="companyIntroduction-containe-header">
        <img src="../assets/company/zjss001.jpg" />
      </div>
      <div class="companyIntroduction-containe-header-video">
        <video
          muted="true"
          width="100%"
          playsinline=""
          x5-video-player-type="h5"
          poster="../assets/banner/bn8.jpg"
          onended="myfun()"
          src=""
          type="video/mp4"
          autoplay="false"
        ></video>
      </div>
      <div class="companyIntroduction-containe-center">
        <el-container>
          <el-aside class="companyIntroduction-containe-center-left" width="15%">
            <div class="box sort_menu">
              <h2>品牌文化<span>BRAND CULTURE</span></h2>

              <ul class="sort">
                <li
                  class="about_a"
                  @click="handleClick(index)"
                  v-for="(item, index) in barArray"
                  :key="index"
                >
                  <a>{{ item }}</a>
                </li>
              </ul>
            </div>
          </el-aside>
          <el-main class="companyIntroduction-containe-center-right">
            <div
              class="companyIntroduction-containe-center-right-introduction"
              v-if="banerIndex == 1"
            >
              <h3 class="column-title">
                <span>文化传承 <br />cultural heritage</span>
              </h3>
              <div class="companyIntroduction-containe-center-right-introduction-text">
                <img src="../assets/culture/wh1.jpg" alt="" srcset="" />
                <h4>1、酿造文化</h4>
                <p>
                  护国陈醋的酿造，以大米、麸皮、精盐为主料，以近百种中草药研制而成的曲药配制，经过煮、熬、发酵、装坛、日晒浸泡等十多种工序，酝酿四、五百天方出成品。
                  具有独特的浓厚陈香、味道醇厚、回甜爽口、余味悠长、久存不腐且陈香更浓的特点，对人体有清热解毒、降压促进消化之功能。
                </p>
                <p>
                  《叙永县志》一九三六年修订本就早有记载：“醋亦常食品有由槽房用酒汁制成者”。日晒醋，亦有用麦麸制成者。日麸醋，叙蓬溪乡所造较为著名，亦行销省内外……
                </p>
                <p>
                  民国初年，酱园业先后崛起，有福泰长（后改名四美同）新泰祥、新泰和、利胜祥等商号。一九二一年，商人刘再儒在泸州设分号，从赤水聘张子钦为技师，后传刘少成（
                  刘三醋匠）生产的护国陈醋和酱油远近闻明，尤以四美同产销两旺，刘三醋匠的名声不翼而飞，护国陈醋有“小泸州”之称。
                </p>
                <h4>２、历史价值</h4>
                <p>
                  护国陈醋产生于清末民初，与当时中国民族工业的兴衰存亡一样，它经历了民族工业的兴衰存亡的历史，如：日军轰炸重庆，使护国陈醋在重庆设立分厂的计划破产。第三传人刘振美因参加革命活动（后在重庆渣滓洞牺牲，成为烈士）使其厂房几度被查封。1956年公私合营，该厂积极参加接受政府改造，并将配方传于政府委派的负责人。护国陈醋是中国民族工业的发展缩影，具有十分珍贵的历史价值。
                </p>
                <h4>３、社会价值</h4>
                <p>
                  食醋在我国有悠久的历史。醋不仅能调配菜肴，开胃调味，而且常食还能祛病健身。经质检部门检验护国陈醋对人体有利物质大于国家一级醋标准，所含有害物质小于国家一级标准。泸州市台办主任章科才有一次不小心被油烫伤，章主任当即用护国陈醋涂抹在伤处，被烫伤的皮肤不红不肿不起水泡，伤好后完好如初。除医治烫烧伤外，护国陈醋还可美容，比如把六味地黄丸捣烂加护国陈醋调成糊状涂抹暗疮立马见效，常服护国陈醋还可清热解毒、延缓衰老、防止动脉硬化、降低血压和胆固醇，另仙人掌磨护国陈醋可治愈腮腺炎等，多年来护国陈醋生产车间的工人无一人生怪病，连厂周围的住户也身体倍棒。
                </p>
                <img src="../assets/culture/wh2.jpg" alt="" srcset="" />
                <h4>４、文化价值</h4>
                <p>
                  护国陈醋的发展史本身就具有独特的文化价值，再加上它独特的工艺流程和独特的酿制配方，使它更具有深刻的文化内涵。护国陈醋的传说和故事，以及它的传承方法和护国陈醋博物馆的藏品，都使护国陈醋处处显示着文化价值的特征。
                </p>
                <h4>５、科学价值</h4>
                <p>
                  护国陈醋工艺流程中煮的粥加了药曲以后几十天不变质，以及它久存不生花的特点，都是由于中草药而不是由于加有其他化学成分如防腐剂的原因，有其科学价值，值得深入研究。
                </p>
                <img src="../assets/culture/wh3.jpg" alt="" srcset="" />
              </div>
            </div>
            <div
              class="companyIntroduction-containe-center-right-introduction"
              v-else-if="banerIndex == 2"
            >
              <h3 class="column-title">
                <span>品牌故事 <br />brand story </span>
              </h3>
              <div class="companyIntroduction-containe-center-right-introduction-text">
                <img src="../assets/culture/pp1.jpg" alt="" srcset="" />
                <p>
                  1902年，一位姓刘名再儒的人，祖辈农耕，家贫如洗，来叙蓬溪（护国镇镇旧名）打工学徒，以谋生路。再儒自幼聪明、勤劳节俭，觉长期寄人篱下也非办法，得亲友支助，逐三上赤水，高薪聘请酿醋名师张子清，得其祖传秘方，创办“福泰长”酱园厂，这便是护国陈醋的前身。
                </p>
                <p>
                  民国24年，刘再儒按三子一侄子振美、家美、平美，侄安美的名字将“福泰长”理名为“四美同”，意寓“四美”同心协力，发展酱油业。刘振美上泸州下重庆，以生产经营护国陈醋为掩护，参加地下活动。在重庆，振美结识了郭沫若，并在郭老的全力支持下，创办了华美书屋。其间振美曾打算在重庆设分号销售护国陈醋，但值日军飞机轰炸，未能如愿。1947年，刘振美被捕，解放前夕在重庆中美合作所渣滓洞集中营遭国民党枪杀，成为革命烈士。1956年，“四美同”进入公私合营，定名“公私合营纳溪县护国四美同酱园厂”。
                  改革开放以后，改制为泸州护国陈醋股份有限公司。
                </p>
                <img src="../assets/culture/pp2.jpg" alt="" srcset="" />
                <p>
                  护国陈醋产生于清末民初，护国当时属叙永县第四区所辖，称为叙蓬溪，与当时中国民族工业的兴衰存亡一样，它经历了民族工业的兴衰存亡的历史，如：日军轰炸重庆，使护国陈醋在重庆设立分厂的计划破产。传承人刘振美因参加革命活动使其厂房几度被查封。1956年公私合营，该厂积极参加接受政府改造，并将配方传于政府委派的负责人。护国陈醋是中国民族工业的发展缩影，具有十分珍贵的历史价值。
                </p>
                <p>
                  为了宏扬中华民族优秀传统的企业文化，护国陈醋公司收集整理了具有历史价值和文化价值的部分文物及酿造工具用具，在泸州设立了护国陈醋博物馆以传承独特的产品及传统文化。
                </p>
                <img src="../assets/culture/pp3.jpg" alt="" srcset="" />
                <p>
                  食醋在我国有着悠久历史。目前，国内生产的醋品种很多，各地都有名醋，而四川泸州的“护国陈醋”却有其与众不同之处，它是用特有配方配制的“醋曲”，对食用醋进行纯天然微生物发酵后，经米醅糖化，麦麸醋化而成，系川南一带代表性产品。据《叙永县志》一九三六年修订本记载：叙蓬溪乡（原为叙永县叙蓬溪乡现为泸州市纳溪区护国镇）所造较为著名，亦行销省内外……至此，“护国陈醋”远近闻名，当时就誉为“川南第一醋”。经质检部门检验护国陈醋的主要指标醋酸、氨基酸、态氮、还原糖都大于国家一级醋标准，所含对人体有害物质小于国家一级醋标准。常服可消除疲劳、延缓衰老、防止动脉硬化、降低血压和胆固醇等。据说：在护国战争（讨袁）期间蔡锷将军和朱德总司令都非常关心护国陈醋，谢觉哉也喜食护国陈醋，其夫人王定国曾题词：“护国陈醋，美味可口”。
                </p>
                <img src="../assets/culture/pp4.jpg" alt="" srcset="" />
                <p>
                  护国陈醋需经过四个步骤、三十六道工序，日晒夜露四、五百天后方出成品，其独特传统工艺，不同凡响的风味，深受广大消费者的喜爱。近年来，产品先后荣获四川省人民政府“四川省非物质文化遗产”、四川省工商行政管理局“四川省著名商标”、中华人民共和国商务部授予的“中华老字号”、“四川省人民政府
                  农业产业化经营重点龙头企业”等荣誉称号。
                </p>
                <h4 style="text-align: center; color: red">“护国陈醋”的传说</h4>
                <p>
                  四川省南部的永宁河畔，有一个叫叙蓬溪的小镇，公元1915年，袁世凯复辟称帝，蔡锷将军发兵声讨，设司令部于叙蓬溪和大州驿之间的湾头。1916年7月，蔡锷于军书之暇，亲将永宁河畔的一座石岩题名为“护国岩”，并作了诗和文以纪实。此后，当地人民为纪念护国讨胜利和缅怀蔡将军，把叙蓬溪改称为护国镇。
                </p>
                <img
                  style="width: 50%; text-align: center"
                  src="../assets/culture/pp5.png"
                  alt=""
                  srcset=""
                />
                <p style="text-align: center">摘自中国民间文学集成《纳溪县资料集》</p>
                <p style="text-align: center">一九八八年五月三十日出版</p>
                <p>
                  纳溪的护国镇过去叫叙蓬溪。很早以前，叙蓬溪街上有个商人叫胡仁义。他爱接济穷人，遇到灾荒年辰，常在自家门口煮几锅稀饭，舀给那些逃荒要饭的人。时间长了，大家都叫他胡二爷。
                </p>
                <p>
                  胡二爷四十岁才得一千金，爱得要命。视如掌上明珠，这千金叫胡蓉花。胡蓉花长到十四、五岁，才一点点高，样子也不好看，黄皮寡瘦。像个姜疙瘩。胡二爷为这事伤透脑筋，四处寻医找药也没得用，有的跑摊医生摸了她的脉也不敢开单子，有的医生呢更是奇怪，看到样子就打转身。胡二爷摸不到头脑。
                </p>
                <p>
                  有一天，胡二爷横下一条心，在街上巴了张条子，哪个医好了我家小女的病，愿以重金酬谢。一时间，叙蓬溪街上都在摆谈这件事。这些时候
                  ，一个年轻太医来到胡家，自称是有祖传秘方，专医怪病。胡二爷看这个年轻人，二十来岁，人才不错，说话也得随和，也能讲些医术，就答应让他医医看。这太医给胡小姐摸了脉，看了舌苔，心头有了数，没有开腔，也没有开药单。每天早上就把胡小姐带到后花园中，让她采花，慢慢胡小姐精神也有些好转。有一天太医给胡小姐开了一付下药，胡小姐一连拉了三天的肚子。胡二爷心头有些着急，害怕出事，这太医呢，不以为然，每天胡二爷为女儿熬药，总是要先尝一口，感觉香气浓，酸味重。
                </p>
                <p>
                  原来，胡小姐心中有热，热又积食，长年消化不良，所以，人消瘦，个子也长不高，参症下药。三个月后，胡小姐胃口大开。过了半年，胡小姐身体也恢复了健康，人了年轻漂亮了。
                </p>
                <p>
                  胡二爷也不失前言，重金酬谢，并把胡小姐许配给太医。成亲那天晚上，小姐才晓得她吃的药全都是用麸皮、大米和她在后花园中采来的中草药酿出来的醋。
                </p>
                <p>从此以后，大家就把这醋叫护国陈醋。</p>
              </div>
            </div>
            <div
              class="companyIntroduction-containe-center-right-history"
              v-else-if="banerIndex == 3"
            >
              <h3 class="column-title">
                <span>博物馆<br />museum</span>
              </h3>
              <img
                style="width: 50%; text-align: center"
                src="../assets/culture/bwg1.jpg"
                alt=""
                srcset=""
              />
              <p style="text-align: center">“护国岩”蔡锷将军题</p>
              <p style="text-align: center">护国陈醋因此而得名</p>
              <img
                style="width: 50%; text-align: center"
                src="../assets/culture/bwg2.jpg"
                alt=""
                srcset=""
              />
              <p style="text-align: center">护国陈醋博物馆</p>
            </div>
            <!-- <div
            class="companyIntroduction-containe-center-right-history"
            v-else-if="banerIndex == 4"
          >
            <h3 class="column-title">
              <span>基地展示<br />base display</span>
            </h3>
            <img src="../assets/company/jd.png" alt="" srcset="" />
          </div>  -->
            <!-- <div
            class="companyIntroduction-containe-center-right-history"
            v-else-if="banerIndex == 5"
          >
            <h3 class="column-title">
              <span>领导关怀<br />leadership care</span>
            </h3>
            <img src="../assets/company/ld.png" alt="" srcset="" />
          </div> -->
          </el-main>
        </el-container>
      </div>
    </div>
    <div class="ssWeb-container-footer">
      <Footer />
    </div>
  </div>
</template>

<script>
import Banner from "@/pages/Banner.vue";
import Footer from "@/pages/Footer.vue";
export default {
  components: {
    Banner,
    Footer,
  },
  data() {
    return {
      banerIndex: 1,
      // barFlag: true,
      barArray: ["文化传承", "品牌故事", "博物馆"],
      imgAray1: [
        { imgUrl: require("../assets/company/ry1.png") },
        { imgUrl: require("../assets/company/ry2.png") },
        { imgUrl: require("../assets/company/ry3.png") },
        { imgUrl: require("../assets/company/ry4.png") },
      ],
    };
  },
  methods: {
    handleClick(index) {
      this.banerIndex = index + 1;
      console.log(this.banerIndex);
    },
  },
};
</script>

<style scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  /* width: 200px; */
  width: 100%;
  min-height: 400px;
}
.companyIntroduction-container {
  width: 80%;
  margin: 0 auto;
  text-align: center;
}
.companyIntroduction-containe-header {
  width: 100%;
  display: none;
}
.companyIntroduction-containe-header img {
  width: 100%;
}
.companyIntroduction-containe-header-video,
.companyIntroduction-containe-header-video video {
  width: 100%;
}
.companyIntroduction-containe-center {
  width: 100%;
}
.companyIntroduction-containe-center-left {
  /* width: 20%; */
  /* border: 1px solid black; */
  /* display: none; */
  width: 100%;
  float: left;
}
.companyIntroduction-containe-center-left-title {
  width: 100%;
  text-align: center;
  font: 24px;
  line-height: 24px;
  margin-top: 20px;
}
.companyIntroduction-containe-center-left-title:hover {
  background-color: #b90d0d;
  color: #e6e6e6;
  transform: all 0.3s;
}
.companyIntroduction-containe-center-right {
  width: 79%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.05);

  /* border: 1px solid black; */
}
.companyIntroduction-containe-center-right-introduction {
  width: 100%;
  margin-bottom: 50px;

  /* border: 1px solid black; */
}

.companyIntroduction-containe-center-right-introduction-text {
  color: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  transition: all 0.5s ease;
}
.companyIntroduction-containe-center-right-introduction-text {
  text-align: left;
  padding: 0 5px;
}
.companyIntroduction-containe-center-right-introduction-text:hover {
  color: rgba(0, 0, 0, 1);
  box-shadow: 5px 0 10px rgba(0, 0, 0, 0.4);
}
.companyIntroduction-containe-center-right-introduction-text img {
  width: 100%;
  padding: 5px 0;
  margin: 0 auto;
  display: block;
}
/* .companyIntroduction-containe-center-right-introduction-text img {
  width: 100%;
} */
.companyIntroduction-containe-center-right-introduction p {
  text-indent: 2em;
  font-size: 14px;
  line-height: 28px;
  font-family: Arial, Helvetica, sans-serif;
  /* text-align: left; */
  text-align: start;
}
.companyIntroduction-containe-center-right-history {
  width: 100%;
  /* border: 1px solid black; */
}
.companyIntroduction-containe-center-right-history img {
  width: 100%;
  border-radius: 10px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  translate: all 5s line;
}
.companyIntroduction-containe-center-right-history img:hover {
  /* transform: scale(1.05); 放大元素 */
  box-shadow: 5px 0 10px rgba(0, 0, 0, 0.4); /* 添加阴影效果 */
}
.column-title {
  font-size: 20px;
  color: #fff;
  margin-bottom: 25px;
  font-weight: 400;
  text-align: center;
}
/* @media (max-width: 768px) {
  .column-title {
    font-size: 12px;
    line-height: 20px;
  }
  .companyIntroduction-containe-center-right-introduction p {
    font-size: 10px;
    line-height: 12px;
  }
} */
.column-title span {
  background: #b90d0d;
  display: inline-block;
  padding: 5px 60px;
  border-radius: 40px;
  position: relative;
}
.column-title span:before {
  content: "";
  width: 8px;
  height: 8px;
  background: #fff;
  display: block;
  position: absolute;
  margin-left: -20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  box-sizing: border-box;
}
.el-carousel__item img {
  /* height: 100%; */
  width: 100%;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.box {
  /* margin-bottom: 20px; */
  border: 1px solid #6d412d;
}
.box h2 {
  margin-top: 0;
  text-align: center;
  font-family: 宋体;
  font-size: 20px;
  color: rgb(255, 255, 255);
  font-weight: normal;
  padding-top: 20px;
  padding-bottom: 20px;
  background: url(../assets/h1.png) 0% 100% no-repeat;
}
.box h2 span {
  display: block;
  line-height: 20px;
  text-transform: uppercase;
  font-size: 12px;
  font-family: "Arial";
}
.box ul {
  list-style-type: none;
  display: block;
  padding: 0;
  /* list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  unicode-bidi: isolate; */
}
.box ul li {
  list-style-type: none;

  position: relative;
  z-index: 999;
}
.sort li a {
  /* color: #b90d0d; */
  display: block;
  line-height: 35px;
  font-family: 宋体;
  /* padding-left: 45px; */
  font-size: 14px;
  background: url(../assets/nav3.png) 0px no-repeat;
  border-bottom: 1px solid rgb(89, 87, 87);
}
.sort li a:hover {
  color: #e87518;
  outline: none;
  text-decoration: none;
}
@media (max-width: 768px) {
  .column-title {
    font-size: 12px;
    line-height: 20px;
  }
  .companyIntroduction-containe-center-right-introduction p {
    font-size: 10px;
    line-height: 12px;
  }
  .companyIntroduction-container {
    width: 100%;
  }
  .sort li a {
    font-size: 12px;
    background: none;
  }
  .box h2 {
    font-size: 14px;
    padding-top: 14px;
    padding-bottom: 14px;
  }
}
</style>
