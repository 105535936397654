<template>
  <div id="app">
    <div class="ssWeb-container">
      <!-- <router-link to="/home">首页</router-link>
      <router-link to="/about">关于我们</router-link>
      <router-link to="/product">产品中心</router-link>
      <router-link to="/culture">品牌文化</router-link> -->
      <router-view></router-view>
      <!-- 导航菜单栏 -->
      <!-- <div class="ssWeb-container-baner">
        <Banner @child-message="(item) => (bannerIndex = item)" />
      </div> -->
      <!-- 首页 -->
      <!-- <div v-if="bannerIndex == 1" class="ssWeb-container-home">
        <HomePage />
      </div> -->
      <!-- 走进生生 -->
      <!-- <div v-else-if="bannerIndex == 2" calss="ssWeb-container-company">
        <CompanyIntroduction />
      </div> -->
      <!-- 产品中心 -->
      <!-- <div v-else-if="bannerIndex == 3" calss="ssWeb-container-product">
        <ProductCenter />
      </div> -->
      <!-- 品牌文化 -->
      <!-- <div v-else-if="bannerIndex == 4" calss="ssWeb-container-product">
        <BrandCulture />
      </div> -->
      <!-- 页脚 -->
      <!-- <div class="ssWeb-container-footer">
        <Footer />
      </div> -->
    </div>
  </div>
</template>

<script>
// import Banner from "./pages/Banner.vue";
// import CompanyIntroduction from "./pages/CompanyIntroduction.vue";
// import Footer from "./pages/Footer.vue";
// import HomePage from "./pages/HomePage.vue";
// import ProductCenter from "./pages/ProductCenter.vue";
// import BrandCulture from "./pages/BrandCulture.vue";
export default {
  name: "App",
  components: {
    // Banner,
    // HomePage,
    // CompanyIntroduction,
    // ProductCenter,
    // Footer,
    // BrandCulture,
  },
  data() {
    return {
      imgAray: [
        { imgUrl: require("../src/assets/banner/bn8.jpg") },
        { imgUrl: require("../src/assets/banner/bn10.jpg") },
        { imgUrl: require("../src/assets/banner/bn9.jpg") },
      ],
      bannerIndex: 1,
    };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
</style>
