import Vue from 'vue'
import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from '@/router'

Vue.use(Element, { size: 'small', zIndex: 3000 });
Vue.config.productionTip = false
// 清除默认样式
const noStyle = `
  html, body,ul{
    margin: 0;
  } `;



// 创建style元素并添加到head中
const style = document.createElement('style');
style.textContent = noStyle;
document.head.appendChild(style);
new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
