<template>
  <div class="swiper-container swiper1">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="(item, index) in srcAry" :key="index">
        <img :src="item.imgUrl" alt="" srcset="" />
      </div>
      <!-- <div class="swiper-slide">Slide 2</div> -->
      <!-- <div class="swiper-slide">Slide 3</div> -->
    </div>
    <!-- 如果需要分页器 -->
    <div v-show="swiperType2" class="swiper-pagination"></div>

    <!-- 如果需要导航按钮 -->
    <div v-show="isButton" class="swiper-button-prev"></div>
    <div v-show="isButton" class="swiper-button-next"></div>

    <!-- 如果需要滚动条 -->
    <!-- <div class="swiper-scrollbar"></div> -->
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.css";
export default {
  data() {
    return {
      screenWidth: 0,
      bannerHeight: 0,
    };
  },
  props: {
    srcAry: {
      type: Array,
      reuqired: true,
    },
    imgWidth: { type: Number, reuqired: true },
    imgheight: { type: Number, reuqired: true },
    swiperDelay: { type: Number, default: 3000 },
    swiperType1: { type: Boolean, reuqired: true },
    swiperType2: { type: Boolean, reuqired: true },
    isButton: { type: Boolean, reuqired: true, default: false },
  },
  mounted() {
    new Swiper(".swiper1", {
      observer: true, //开启动态检查器，监测swiper和slide
      // direction: "vertical", // 垂直切换选项
      loop: true, // 循环模式选项
      autoplay: true,
      // autoplay: { delay: this.swiperDelay },

      // 如果需要分页器
      pagination: {
        el: ".swiper-pagination",
      },

      // 如果需要前进后退按钮
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },

      // 如果需要滚动条
      scrollbar: {
        el: ".swiper-scrollbar",
      },
    });
  },
};
</script>

<style scoped>
.swiper-container,
.swiper-wrapper,
.swiper-slide,
.swiper-slide img {
  width: 100%;
}
</style>
