<template>
  <div>
    <div class="ssWeb-container-baner">
      <Banner />
    </div>
    <div class="companyIntroduction-container">
      <div class="companyIntroduction-containe-header">
        <img src="../assets/company/zjss001.jpg" />
      </div>
      <div class="companyIntroduction-containe-header-video">
        <video
          muted="true"
          width="100%"
          playsinline=""
          x5-video-player-type="h5"
          poster="../assets/banner/bn8.jpg"
          onended="myfun()"
          src=""
          type="video/mp4"
          autoplay="false"
        ></video>
      </div>
      <div class="companyIntroduction-containe-center">
        <el-container>
          <el-aside class="companyIntroduction-containe-center-left" width="15%">
            <div class="box sort_menu">
              <h2>关于我们<span>About us</span></h2>

              <ul class="sort">
                <li
                  class="about_a"
                  @click="handleClick(index)"
                  v-for="(item, index) in barArray"
                  :key="index"
                >
                  <a>{{ item }}</a>
                </li>
              </ul>
            </div>
          </el-aside>
          <el-main class="companyIntroduction-containe-center-right">
            <div
              class="companyIntroduction-containe-center-right-introduction"
              v-if="banerIndex == 1"
            >
              <h3 class="column-title">
                <span>公司简介 <br />company introduction</span>
              </h3>
              <div class="companyIntroduction-containe-center-right-introduction-text">
                <p>
                  泸州护国味业有限公司（以下简称“公司”）发源于清末民初的“福泰长”酱园厂，历经多轮改制，于2023年3月由四川元景达食品有限公司全资收购，纳入泸州老窖集团食品产业体系。目前，公司注册资本7000万元，主营产品包括护国陈醋、护国酱油、护国料酒和复合调味品等，先后荣获商务部“中华老字号”、“全国食品行业名牌产品”、“中华全国供销合作总社重点龙头企业”、“全国文明单位”、“四川省非物质文化遗产单位”等称号。
                </p>
                <p>
                  1889年至今，护国味业已持续无间断酿造生产逾百年，技艺悠久、文化深厚，是中国调味品行业固态酿造技艺、巴蜀风味及酱园文化的典型代表，拥有两项“非遗”技艺——享有“川南第一醋”美誉的护国陈醋传统酿制技艺和护国酱油传统酿制技艺。护国陈醋选用优质麦麸、大米为主要原料，经过采药、制曲、煮熬、发酵、拌醅、装罐、日晒、夜露、浸泡等四个步骤、三十六道工序，产品回味爽口，余味悠长，久贮不腐，是传统小坛醋醅工艺的典型代表。护国酱油选用优质大豆、面粉、井盐为原料，经过选料、浸泡、蒸煮、冷却、拌粉、接种、制曲、发酵、淋油、晒露浓缩等十八道工序精制而成，产品酱香浓郁、醇和爽口、咸淡适宜，是巴蜀传统酿制工艺典范。公司作为元景达调味品产业布局的先手棋和重要引擎，坚持赓续非遗技艺、民族工业发展和红色革命等内生基因，历经十代非遗技艺传承人，薪火相授、匠心酿造，着力将“护国岩”中华老字号发扬光大，为泸州打造成为“中国调味品之都”贡献新的力量。
                </p>
                <!-- <p>
                作为“正宗四川麸醋工艺传承者”“高盐固态酱油标准制定者”及“高原酿造专家”，生生护国以“传承传统工艺，分享自然美味”为使命，秉持“正直诚信，用户第一、客户优先、共创造同分享”的核心价值观，坚持“以质为本、以味为魂、凭信而立”的经营思想，创新奋进，跨越发展，努力打造成为中国传统酿造行业中责任担当、值得信赖、受人尊敬的百年企业。
              </p> -->
              </div>
            </div>
            <div
              class="companyIntroduction-containe-center-right-introduction"
              v-else-if="banerIndex == 2"
            >
              <h3 class="column-title">
                <span>发展历程 <br />development history</span>
              </h3>
              <div class="companyIntroduction-containe-center-right-introduction-text">
                <p>
                  护国镇素有“醋乡”美称。“护国陈醋”历史悠久，据《纳溪县志》记载，可追溯到清末民初。当年有位姓刘名再儒的人，祖辈农耕，家贫如洗，逐来叙蓬溪（护国镇旧名）打工做学徒，以谋生路。机缘巧合，得以高薪聘请酿醋名师张子清得其祖传秘方创“福泰长”酱园厂，这便是护国陈醋公司的前身。
                </p>
                <div
                  class="companyIntroduction-containe-center-right-introduction-text-img"
                >
                  <img src="../assets/company/hst1.png" alt="" srcset="" />
                </div>
                <p>
                  “福泰长”经营有方，生意兴隆，其麸醋赢利尤丰。刘再儒认定醋乃人们生活必需，又有益于健康，前景可观，加之护国镇独有的水质和气候条件，使其醋质色、香、味俱佳，在酱园业中独树一帜，远胜当时“新泰祥”、“利胜祥”、“新泰和”几家。据《叙永县志》一九三六年修订本记载：“醋亦常食品有由槽房用酒汁制成者”。曰：晒醋，亦有用麦麸制成者。曰：麸醋，叙蓬溪乡（原为叙永县叙蓬溪乡现为泸州市纳溪区护国镇）所造较为著名，亦行销省内外;至此，“护国陈醋”远近闻名，立足泸州，辐射西南。
                </p>
                <div
                  class="companyIntroduction-containe-center-right-introduction-text-img"
                >
                  <img src="../assets/company/hst2.png" alt="" srcset="" />
                </div>
                <p>
                  民国24年，刘再儒按三子一侄子振美、家美、平美，侄安美的名字将“福泰长”理名为“四美同”，意寓“四美”同心协力，发展酱油业。第三代传人刘振美上泸州下重庆，以生产经营护国陈醋为掩护，参加地下活动。在重庆，振美结识了郭沫若，并在郭老的全力支持下，创办了华美书屋。其间振美曾打算在重庆设分号销售护国陈醋，但值日军飞机轰炸，未能如愿。1947年，刘振美被捕，解放前夕在重庆中美合作所渣滓洞集中营遭国民党枪杀，成为革命烈士。
                </p>
                <p>
                  1956年，“四美同”进入公私合营，定名“公私合营纳溪县护国四美同酱园厂”。
                </p>
                <p>六十年代过渡为社办企业。</p>
                <p>改革开放以后，改制为泸州护国陈醋有限公司。</p>
                <p>
                  2003年10月由四川华蜀集团有限公司斥资收购后进行资产重组，使古老的护国陈醋焕发出勃勃生机。
                </p>
                <p>2010年11月，改名为泸州护国陈醋股份有限公司。</p>
                <p>2023年3月，成立泸州护国味业有限公司。</p>
              </div>
            </div>
            <div
              class="companyIntroduction-containe-center-right-history"
              v-else-if="banerIndex == 3"
            >
              <h3 class="column-title">
                <span>企业荣誉<br />corporate honors</span>
              </h3>
              <el-carousel :interval="4000" height="672px">
                <el-carousel-item v-for="item in imgAray1" :key="item">
                  <img :src="item.imgUrl" alt="" srcset="" />
                  <!-- <h3 class="medium">12312</h3> -->
                </el-carousel-item>
              </el-carousel>
            </div>
            <div
              class="companyIntroduction-containe-center-right-history"
              v-else-if="banerIndex == 4"
            >
              <h3 class="column-title">
                <span>基地展示<br />base display</span>
              </h3>
              <img src="../assets/company/jd.png" alt="" srcset="" />
            </div>
            <div
              class="companyIntroduction-containe-center-right-history"
              v-else-if="banerIndex == 5"
            >
              <h3 class="column-title">
                <span>领导关怀<br />leadership care</span>
              </h3>
              <img src="../assets/company/ld.png" alt="" srcset="" />
            </div>
            <div
              class="companyIntroduction-containe-center-right-introduction"
              v-else-if="banerIndex == 6"
            >
              <h3 class="column-title">
                <span>护国战争纪念馆<br />national protection war memorial hall</span>
              </h3>
              <div class="companyIntroduction-containe-center-right-introduction-text">
                <p>
                  泸州市纳溪区护国战争纪念馆地处纳溪区棉花坡紫阳大道，原名陶家大院，始建于1852年，至今160多年历史。陶家大院曾是护国战争中棉花坡战役的指挥部，属省级文物保护单位。为弘扬“护国”精神，传承纳溪文化，2012年，泸州护国陈醋股份有限公司按照中共纳溪区委、纳溪区人民政府“修旧如旧”的原则，投资近2000万元，逐步恢复陶家大院原貌，成为中国一个护国战争纪念馆。
                </p>
                <img src="../assets/company/zlg1.jpg" alt="" srcset="" />
                <p style="text-align: center">护国战争纪念馆</p>
                <p>
                  护国战争博物馆是四合院布局，有三朝门、三重堂、四事院、八天井。大朝门坐东向西，面迎长江，似有财源滚滚而来。一对大石狮雄距门前，忠实地守护着“陶园”。园内厅前石栏上瑞草、莲花、牡丹、兰花；房梁支柱均有灵兽、人物石刻，有韵、有神，每一幅画，刀笔流畅、刻工精湛，每一组图像，一个故事，活灵活现，栩栩入生。
                </p>
                <p>
                  护国战争博物馆大大小小38间住房杂屋，无论过厅、堂屋、厢房、后院、花园，都可以从房顶、房角看见当年象征富贵荣华、福禄寿喜、吉祥如意的反翘屋角、鱼鳌作脊、显其灵秀、气势、堂皇。从门楣，梁柱上可谓画栋连云、瑞草、灵兽、人物、花卉，不仅展示了川南民居建筑的传统文化，而且表现了建筑格调高雅的凝重。虽然历经岁月沧桑和人事变迁，但绝大部份仍然保护完好。“一园花间全依水，三重厅堂直到山”，这在川南民居建筑史上，可谓占尽风流。
                </p>
                <p>
                  护国战争纪念馆馆内设有：“过厅（接待厅）”、“护国战争纪念馆综合厅”、“朱德展厅”、“纳溪人民支援护国战争展厅”、“蔡锷厅”、“影视厅”、“棉花坡战役厅”、“民间民俗文化厅”、“纳溪人文展厅”。展馆真实地记录了护国战争棉花坡战役的硝烟烽火，铁血讨袁的历史，展现了蔡锷、朱德讨袁英烈护国护民的铮铮铁骨，纳溪人民运送粮食弹药，抢救、照顾伤员，掩护护国军将士的侠骨柔情。“滇南壮士集云溪，听铁马声中，三军洪水开天地；冀北胸襟环纳带，看朱坪阵上，万里催烟动古今”。护国军将士、纳溪人民齐心协力，同仇敌慨，用生命和鲜血重创北洋军，攻占棉花坡及上下朱坪，直捣朝阳观，迫使北洋军溃逃九川山，退守飞来寺，从而赢得了棉花坡战役的胜利，为护国战争的全面胜利立下了丰功伟绩。
                </p>
                <p>
                  护国战争纪念馆再现了硝烟弥漫的护国战争战场，留下了蔡锷、朱德等先辈们讨袁护国惊天地、泣鬼神的威猛丰采；展示了纳溪民间民俗的厚重文化；推出了一方水土的文人墨客、杰出人士、英模人物。护国战争纪念馆是历史的回顾，是优秀文化的宣扬，是开展爱国主义教育的阵地。
                </p>
              </div>
            </div>
          </el-main>
        </el-container>
      </div>
    </div>
    <div class="ssWeb-container-footer">
      <Footer />
    </div>
  </div>
</template>

<script>
import Banner from "@/pages/Banner.vue";
import Footer from "@/pages/Footer.vue";
export default {
  components: {
    // AlonePage,
    // SwiperCom,
    Banner,
    Footer,
  },
  data() {
    return {
      banerIndex: 1,
      // barFlag: true,
      barArray: [
        "公司简介",
        "发展历程",
        "企业荣誉",
        "基地展示",
        "领导关怀",
        "护国战争纪念馆",
      ],
      imgAray1: [
        { imgUrl: require("../assets/company/ry1.png") },
        { imgUrl: require("../assets/company/ry2.png") },
        { imgUrl: require("../assets/company/ry3.png") },
        { imgUrl: require("../assets/company/ry4.png") },
      ],
      url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      srcList: [
        "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
        "https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg",
      ],
    };
  },
  methods: {
    handleClick(index) {
      this.banerIndex = index + 1;
      console.log(this.banerIndex);
    },
  },
};
</script>

<style scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  /* width: 200px; */
  width: 100%;
  min-height: 400px;
}
.companyIntroduction-container {
  width: 80%;
  margin: 0 auto;
  text-align: center;
}
.companyIntroduction-containe-header {
  width: 100%;
  display: none;
}
.companyIntroduction-containe-header img {
  width: 100%;
}
.companyIntroduction-containe-header-video,
.companyIntroduction-containe-header-video video {
  width: 100%;
}
.companyIntroduction-containe-center {
  width: 100%;
}
.companyIntroduction-containe-center-left {
  /* width: 20%; */
  /* border: 1px solid black; */
  /* display: none; */
  width: 100%;
  float: left;
}
.companyIntroduction-containe-center-left-title {
  width: 100%;
  text-align: center;
  font: 24px;
  line-height: 24px;
  margin-top: 20px;
}
.companyIntroduction-containe-center-left-title:hover {
  background-color: #b90d0d;
  color: #e6e6e6;
  transform: all 0.3s;
}
.companyIntroduction-containe-center-right {
  width: 79%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.05);

  /* border: 1px solid black; */
}
.companyIntroduction-containe-center-right-introduction {
  width: 100%;
  margin-bottom: 50px;

  /* border: 1px solid black; */
}

.companyIntroduction-containe-center-right-introduction-text {
  color: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  transition: all 0.5s ease;
}
.companyIntroduction-containe-center-right-introduction-text:hover {
  color: rgba(0, 0, 0, 1);
  box-shadow: 5px 0 10px rgba(0, 0, 0, 0.4);
}
.companyIntroduction-containe-center-right-introduction-text-img {
  width: 100%;
}
.companyIntroduction-containe-center-right-introduction-text img {
  width: 100%;
}
.companyIntroduction-containe-center-right-introduction p {
  text-indent: 2em;
  font-size: 14px;
  line-height: 28px;
  font-family: Arial, Helvetica, sans-serif;
  /* text-align: left; */
  text-align: start;
}
.companyIntroduction-containe-center-right-history {
  width: 100%;
  /* border: 1px solid black; */
}
.companyIntroduction-containe-center-right-history img {
  width: 100%;
  border-radius: 10px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  translate: all 5s line;
}
.companyIntroduction-containe-center-right-history img:hover {
  /* transform: scale(1.05); 放大元素 */
  box-shadow: 5px 0 10px rgba(0, 0, 0, 0.4); /* 添加阴影效果 */
}
.column-title {
  font-size: 20px;
  color: #fff;
  margin-bottom: 25px;
  font-weight: 400;
  text-align: center;
}

.column-title span {
  background: #b90d0d;
  display: inline-block;
  padding: 5px 60px;
  border-radius: 40px;
  position: relative;
}
.column-title span:before {
  content: "";
  width: 8px;
  height: 8px;
  background: #fff;
  display: block;
  position: absolute;
  margin-left: -20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  box-sizing: border-box;
}
.el-carousel__item img {
  /* height: 100%; */
  width: 100%;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.box {
  /* margin-bottom: 20px; */
  border: 1px solid #6d412d;
}
.box h2 {
  margin-top: 0;
  text-align: center;
  font-family: 宋体;
  font-size: 20px;
  color: rgb(255, 255, 255);
  font-weight: normal;
  padding-top: 20px;
  padding-bottom: 20px;
  background: url(../assets/h1.png) 0% 100% no-repeat;
}
.box h2 span {
  display: block;
  line-height: 20px;
  text-transform: uppercase;
  font-size: 12px;
  font-family: "Arial";
}
.box ul {
  list-style-type: none;
  display: block;
  padding: 0;
  /* list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  unicode-bidi: isolate; */
}
.box ul li {
  list-style-type: none;

  position: relative;
  z-index: 999;
}
.sort li a {
  /* color: #b90d0d; */
  display: block;
  line-height: 35px;
  font-family: 宋体;
  /* padding-left: 45px; */
  font-size: 14px;
  background: url(../assets/nav3.png) 0px no-repeat;
  border-bottom: 1px solid rgb(89, 87, 87);
}
.sort li a:hover {
  color: #e87518;
  outline: none;
  text-decoration: none;
}
@media (max-width: 768px) {
  .column-title {
    font-size: 12px;
    line-height: 20px;
  }
  .companyIntroduction-containe-center-right-introduction p {
    font-size: 10px;
    line-height: 12px;
  }
  .companyIntroduction-container {
    width: 100%;
  }
  .sort li a {
    font-size: 12px;
    background: none;
  }
  .box h2 {
    font-size: 14px;
    padding-top: 14px;
    padding-bottom: 14px;
  }
}
</style>
