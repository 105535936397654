<template>
  <div class="swiper-container" :class="{ swiper1: swiperType1, swiper2: swiperType2 }">
    <div class="swiper-wrapper" v-if="srcAry.length">
      <div
        class="swiper-slide swiper-slide-content"
        v-for="(item, index) in srcAry"
        :key="index"
      >
        <img :src="item.imgUrl" alt="" srcset="" />
        <p>{{ item.name }}</p>
        <!-- <el-image :src="item.imgUrl" :lazy="false"> </el-image> -->
      </div>
      <!-- <div class="swiper-slide">Slide 2</div> -->
      <!-- <div class="swiper-slide">Slide 3</div> -->
    </div>
    <!-- 如果需要分页器 -->
    <!-- <div v-show="swiperType2" class="swiper-pagination"></div> -->

    <!-- 如果需要导航按钮 -->
    <div v-show="isButton" class="swiper-button-prev"></div>
    <div v-show="isButton" class="swiper-button-next"></div>

    <!-- 如果需要滚动条 -->
    <!-- <div class="swiper-scrollbar"></div> -->
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.css";
export default {
  data() {
    return {
      screenWidth: 0,
      bannerHeight: 0,
    };
  },
  props: {
    srcAry: {
      type: Array,
      reuqired: true,
    },
    imgWidth: { type: Number, reuqired: true },
    imgheight: { type: Number, reuqired: true },
    swiperDelay: { type: Number, default: 3000 },
    swiperType1: { type: Boolean, reuqired: true },
    swiperType2: { type: Boolean, reuqired: true },
    isButton: { type: Boolean, reuqired: true, default: false },
  },
  mounted() {
    new Swiper(".swiper2", {
      observer: true, //开启动态检查器，监测swiper和slide
      // direction: "vertical", // 垂直切换选项
      loop: true, // 循环模式选项
      autoplay: { delay: this.swiperDelay },
      // autoplay: true,
      spaceBetween: 20,
      slidesPerView: 4,
      slidesPerColumn: 2,
      slidesPerColumnFill: "row",
      // observer:true,
      observeParents: true,

      // 如果需要分页器
      pagination: {
        el: ".swiper-pagination",
      },

      // 如果需要前进后退按钮
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },

      // 如果需要滚动条
      scrollbar: {
        el: ".swiper-scrollbar",
      },
    });
  },
  methods: {
    openPicture(url) {
      console.log(url);
    },
  },
};
</script>

<style scoped>
.swiper-container,
.swiper-wrapper,
.swiper-slide {
  width: 100%;
}
/* .swiper-slide-content {
  margin-right: 10px;
} */
.swiper-slide img {
  width: 90%;
  margin: 10px 20px 10px 10px;
  background-color: #fff;
  border-radius: 10px;
  translate: all 0.3 liner;
}
.swiper-slide img:hover {
  transform: scale(1.1);
  border: 1px solid rgb(252, 4, 4, 0.2);
  box-shadow: 10px 10px 10px 0 rgba(0, 0, 0, 0.3);
}
.swiper-container {
  /* margin: 10px; */
  padding: 10px;
  box-sizing: border-box;
}
.swiper-wrapper {
  background: rgba(0, 0, 0, 0.05);
}
@media (max-width: 768px) {
  .swiper-slide-content p {
    font-size: 10px;
  }
}
</style>
